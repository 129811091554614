html {
  scroll-behavior: smooth;
}

.btn-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem !important;
  transition: all 1s !important;
}

.btn-toggle span {
  display: none;
}

.btn-toggle:hover > span {
  display: inline-block;
  font-size: 1rem;
  white-space: nowrap;
}

.btn-toggle:hover {
  width: 200px;
  border-radius: 0.4rem !important;
}

.btn-social {
  padding: 0 5px;
  margin: 0 5px;
}

.btn-facebook {
  color: var(--white) !important;
  background: linear-gradient(
    222deg,
    #8b9dc3 10%,
    #8b9dc3 30%,
    #3b5999 100%
  ) !important;
  background: -webkit-linear-gradient(
    222deg,
    #8b9dc3 10%,
    #8b9dc3 30%,
    #3b5999 100%
  ) !important;
  background-color: #3b5999;
  border-color: #9eb5e9 !important;
}
.btn-facebook:hover:not(.btn-toggle) {
  background: #3b5999 !important;
}

.btn-instagram {
  color: var(--white) !important;
  background: linear-gradient(
    300deg,
    #f4931b 10%,
    #d83452 30%,
    #533f9d 100%
  ) !important;
  background: -webkit-linear-gradient(
    300deg,
    #f4931b 10%,
    #d83452 30%,
    #533f9d 100%
  ) !important;
  background-color: #d83452;
  border-color: #ebb3be !important;
}
.btn-instagram:hover:not(.btn-toggle) {
  background: #d83452 !important;
}

.btn-whatsapp {
  color: var(--white) !important;
  background: linear-gradient(
    87deg,
    #075e54 10%,
    #128c7e 30%,
    #25d366 100%
  ) !important;
  background-color: #25d366 !important;
  border-color: #abebb7 !important;
}
.btn-whatsapp:hover:not(.btn-toggle) {
  background: #0cb12a !important;
}

.btn-twitter {
  color: var(--white) !important;
  background: linear-gradient(
    222deg,
    #8b9dc3 10%,
    #8b9dc3 30%,
    #17a2b8 100%
  ) !important;
  background: -webkit-linear-gradient(
    222deg,
    #8b9dc3 10%,
    #8b9dc3 30%,
    #17a2b8 100%
  ) !important;
  background-color: #17a2b8;
  border-color: #9eb5e9 !important;
}
.btn-twitter:hover:not(.btn-toggle) {
  background: #17a2b8 !important;
}

.btn-youtube {
  color: var(--white) !important;
  background: linear-gradient(
    180deg,
    #ee7373 10%,
    #db4848 30%,
    #f70000 100%
  ) !important;
  background: -webkit-linear-gradient(
    180deg,
    #ee7373 10%,
    #db4848 30%,
    #f70000 100%
  ) !important;
  background-color: #f70000;
  border-color: #db4848 !important;
}
.btn-youtube:hover:not(.btn-toggle) {
  background: #f70000 !important;
}

.card {
  margin-bottom: 30px;
  box-shadow: 0;
  border: 0;
}

.close {
  transition: all 0.15s ease;
}
.close > span:not(.sr-only) {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  line-height: 17px;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  font-size: 1.25rem;
  display: block;
  transition: all 0.15s ease;
}
.close:hover,
.close:focus {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  outline: none;
}
.close:hover span:not(.sr-only),
.close:focus span:not(.sr-only) {
  background-color: transparent;
}

.close-dark > span:not(.sr-only) {
  color: rgba(255, 255, 255, 0.8);
}

.close-dark:hover > span:not(.sr-only),
.close-dark:focus > span:not(.sr-only) {
  color: var(--white);
}

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
}

article h4:not(:first-child),
article h5:not(:first-child) {
  margin-top: 3rem;
}

article h4,
article h5 {
  margin-bottom: 1.5rem;
}

article figure {
  margin: 3rem 0;
}

article h5 + figure {
  margin-top: 0;
}

.display-1 span,
.display-2 span,
.display-3 span,
.display-4 span {
  display: block;
  font-weight: 300;
}

.heading {
  letter-spacing: 0.025em;
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: 600;
}

.heading-small {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.heading-title {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;
}

.heading-section {
  letter-spacing: 0.025em;
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: uppercase;
}
.heading-section img {
  display: block;
  width: 72px;
  height: 72px;
  margin-bottom: 1.5rem;
}
.heading-section.text-center img {
  margin-left: auto;
  margin-right: auto;
}

.floating {
  animation: floating 3s ease infinite;
  will-change: transform;
}
.floating:hover {
  animation-play-state: paused;
}

.floating-lg {
  animation: floating-lg 3s ease infinite;
}

.floating-sm {
  animation: floating-sm 3s ease infinite;
}

@keyframes floating-lg {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes floating-sm {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-8 {
  opacity: 0.9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

@media (min-width: 576px) {
  .opacity-sm-0 {
    opacity: 0 !important;
  }

  .opacity-sm-1 {
    opacity: 0.1 !important;
  }

  .opacity-sm-2 {
    opacity: 0.2 !important;
  }

  .opacity-sm-3 {
    opacity: 0.3 !important;
  }

  .opacity-sm-4 {
    opacity: 0.4 !important;
  }

  .opacity-sm-5 {
    opacity: 0.5 !important;
  }

  .opacity-sm-6 {
    opacity: 0.6 !important;
  }

  .opacity-sm-7 {
    opacity: 0.7 !important;
  }

  .opacity-sm-8 {
    opacity: 0.8 !important;
  }

  .opacity-sm-8 {
    opacity: 0.9 !important;
  }

  .opacity-sm-10 {
    opacity: 1 !important;
  }
}

@media (min-width: 768px) {
  .opacity-md-0 {
    opacity: 0 !important;
  }

  .opacity-md-1 {
    opacity: 0.1 !important;
  }

  .opacity-md-2 {
    opacity: 0.2 !important;
  }

  .opacity-md-3 {
    opacity: 0.3 !important;
  }

  .opacity-md-4 {
    opacity: 0.4 !important;
  }

  .opacity-md-5 {
    opacity: 0.5 !important;
  }

  .opacity-md-6 {
    opacity: 0.6 !important;
  }

  .opacity-md-7 {
    opacity: 0.7 !important;
  }

  .opacity-md-8 {
    opacity: 0.8 !important;
  }

  .opacity-md-8 {
    opacity: 0.9 !important;
  }

  .opacity-md-10 {
    opacity: 1 !important;
  }
}

@media (min-width: 992px) {
  .opacity-lg-0 {
    opacity: 0 !important;
  }

  .opacity-lg-1 {
    opacity: 0.1 !important;
  }

  .opacity-lg-2 {
    opacity: 0.2 !important;
  }

  .opacity-lg-3 {
    opacity: 0.3 !important;
  }

  .opacity-lg-4 {
    opacity: 0.4 !important;
  }

  .opacity-lg-5 {
    opacity: 0.5 !important;
  }

  .opacity-lg-6 {
    opacity: 0.6 !important;
  }

  .opacity-lg-7 {
    opacity: 0.7 !important;
  }

  .opacity-lg-8 {
    opacity: 0.8 !important;
  }

  .opacity-lg-8 {
    opacity: 0.9 !important;
  }

  .opacity-lg-10 {
    opacity: 1 !important;
  }
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: 0.25rem;
}

.right-1 {
  right: 0.25rem;
}

.bottom-1 {
  bottom: 0.25rem;
}

.left-1 {
  left: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.left-2 {
  left: 0.5rem;
}

.top-3 {
  top: 1rem;
}

.right-3 {
  right: 1rem;
}

.bottom-3 {
  bottom: 1rem;
}

.left-3 {
  left: 1rem;
}

.top-4 {
  top: 1.5rem;
}

.right-4 {
  right: 1.5rem;
}

.bottom-4 {
  bottom: 1.5rem;
}

.left-4 {
  left: 1.5rem;
}

.top-5 {
  top: 3rem;
}

.right-5 {
  right: 3rem;
}

.bottom-5 {
  bottom: 3rem;
}

.left-5 {
  left: 3rem;
}

.top--9 {
  top: -10rem;
}

.right--9 {
  right: -10rem;
}

.bottom--9 {
  bottom: -10rem;
}

.left--9 {
  left: -10rem;
}

.top--8 {
  top: -8rem;
}

.right--8 {
  right: -8rem;
}

.bottom--8 {
  bottom: -8rem;
}

.left--8 {
  left: -8rem;
}

.top--7 {
  top: -6rem;
}

.right--7 {
  right: -6rem;
}

.bottom--7 {
  bottom: -6rem;
}

.left--7 {
  left: -6rem;
}

.top--6 {
  top: -4.5rem;
}

.right--6 {
  right: -4.5rem;
}

.bottom--6 {
  bottom: -4.5rem;
}

.left--6 {
  left: -4.5rem;
}

.top--5 {
  top: -3rem;
}

.right--5 {
  right: -3rem;
}

.bottom--5 {
  bottom: -3rem;
}

.left--5 {
  left: -3rem;
}

.top--4 {
  top: -1.5rem;
}

.right--4 {
  right: -1.5rem;
}

.bottom--4 {
  bottom: -1.5rem;
}

.left--4 {
  left: -1.5rem;
}

.top--3 {
  top: -1rem;
}

.right--3 {
  right: -1rem;
}

.bottom--3 {
  bottom: -1rem;
}

.left--3 {
  left: -1rem;
}

.top--2 {
  top: -0.5rem;
}

.right--2 {
  right: -0.5rem;
}

.bottom--2 {
  bottom: -0.5rem;
}

.left--2 {
  left: -0.5rem;
}

.top--1 {
  top: -0.25rem;
}

.right--1 {
  right: -0.25rem;
}

.bottom--1 {
  bottom: -0.25rem;
}

.left--1 {
  left: -0.25rem;
}

.top-6 {
  top: 4.5rem;
}

.right-6 {
  right: 4.5rem;
}

.bottom-6 {
  bottom: 4.5rem;
}

.left-6 {
  left: 4.5rem;
}

.top-7 {
  top: 6rem;
}

.right-7 {
  right: 6rem;
}

.bottom-7 {
  bottom: 6rem;
}

.left-7 {
  left: 6rem;
}

.top-8 {
  top: 8rem;
}

.right-8 {
  right: 8rem;
}

.bottom-8 {
  bottom: 8rem;
}

.left-8 {
  left: 8rem;
}

.top-9 {
  top: 10rem;
}

.right-9 {
  right: 10rem;
}

.bottom-9 {
  bottom: 10rem;
}

.left-9 {
  left: 10rem;
}

.shadow-sm--hover:hover {
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important;
}

.shadow--hover:hover {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}

.shadow-lg--hover:hover {
  box-shadow: 0 0 3rem rgba(136, 152, 170, 0.175) !important;
}

.shadow-none--hover:hover {
  box-shadow: none !important;
}

@media (min-width: 1200px) {
  .mt--100 {
    margin-top: -100px !important;
  }
  .mr--100 {
    margin-right: -100px !important;
  }
  .mb--100 {
    margin-bottom: -100px !important;
  }
  .ml--100 {
    margin-left: -100px !important;
  }
  .mt--150 {
    margin-top: -150px !important;
  }
  .mb--150 {
    margin-bottom: -150px !important;
  }
  .mt--200 {
    margin-top: -200px !important;
  }
  .mb--200 {
    margin-bottom: -200px !important;
  }
  .mt--300 {
    margin-top: -300px !important;
  }
  .mb--300 {
    margin-bottom: -300px !important;
  }
  .pt-100 {
    padding-top: 100px !important;
  }
  .pb-100 {
    padding-bottom: 100px !important;
  }
  .pt-150 {
    padding-top: 150px !important;
  }
  .pb-150 {
    padding-bottom: 150px !important;
  }
  .pt-200 {
    padding-top: 200px !important;
  }
  .pb-200 {
    padding-bottom: 200px !important;
  }
  .pt-250 {
    padding-top: 250px !important;
  }
  .pb-250 {
    padding-bottom: 250px !important;
  }
  .pt-300 {
    padding-top: 300px !important;
  }
  .pb-300 {
    padding-bottom: 300px !important;
  }
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.text-gray {
  color: var(--gray);
}

.text-black {
  color: var(--black);
}

.text-black::placeholder {
  color: var(--black);
}

.text-gray-dark {
  color: var(--gray-dark);
}

.text-cyan {
  color: var(--cyan);
}

.text-underline {
  text-decoration: underline;
}

.text-through {
  text-decoration: line-through;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-xs {
  font-size: 0.2rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-lg {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

@media (min-width: 576px) {
  .text-xs {
    font-size: 0.75rem !important;
  }

  .text-sm {
    font-size: 1rem !important;
  }

  .text-lg {
    font-size: 1.25rem !important;
  }

  .text-xl {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .text-xs {
    font-size: 1rem !important;
  }

  .text-sm {
    font-size: 1.25rem !important;
  }

  .text-lg {
    font-size: 1.5rem !important;
  }

  .text-xl {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 992px) {
  .text-xs {
    font-size: 1.25rem !important;
  }

  .text-sm {
    font-size: 1.5rem !important;
  }

  .text-lg {
    font-size: 1.75rem !important;
  }

  .text-xl {
    font-size: 1.9rem !important;
  }
}

@media (min-width: 1200px) {
  .text-xs {
    font-size: 1.4rem !important;
  }

  .text-sm {
    font-size: 1.6rem !important;
  }

  .text-lg {
    font-size: 1.85rem !important;
  }

  .text-xl {
    font-size: 2rem !important;
  }
}

.lh-100 {
  line-height: 1;
}

.lh-110 {
  line-height: 1.1;
}

.lh-120 {
  line-height: 1.2;
}

.lh-130 {
  line-height: 1.3;
}

.lh-140 {
  line-height: 1.4;
}

.lh-150 {
  line-height: 1.5;
}

.lh-160 {
  line-height: 1.6;
}

.lh-170 {
  line-height: 1.7;
}

.lh-180 {
  line-height: 1.8;
}

.ls-1 {
  letter-spacing: 0.0625rem;
}

.ls-15 {
  letter-spacing: 0.09375rem;
}

.ls-2 {
  letter-spacing: 0.125rem;
}

.alert-dismissible .close {
  position: absolute;
  right: 0;
  padding: 1rem 1.5rem;
  color: inherit;
}

.alert-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.input-group-merge .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}

.input-group-merge .form-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}

.input-group-prepend .input-group-text {
  border-right: 0;
}

.input-group-append .input-group-text {
  border-left: 0;
}

.form-check-input {
  /* background-color: transparent !important; */
  border: 1px solid var(--white);
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: var(--white);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 191, 0, 0.25);
}

.form-check-input:checked {
  background-color: var(--white);
  border-color: var(--white);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFBF00' stroke-linecap='round' stroke-linejoin='round' stroke-width='6' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--white);
    border-color: var(--white);
}

.valid .input-group-text {
  color: var(--success);
  background-color: var(--white);
  border-color: var(--success);
}

.valid .form-control {
  border-color: var(--success);
}

.invalid .input-group-text {
  color: var(--danger);
  background-color: var(--white);
  border-color: var(--danger);
}

.invalid .form-control {
  border-color: var(--danger);
}

.z-index-99999 {
  z-index: 99999;
}

.bg-gray {
  background-color: var(--gray) !important;
}

.bg-yellow {
  background-color: var(--yellow) !important;
}

.bg-gray-dark {
  background-color: var(--gray-dark) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.react-select__placeholder {
  color: var(--gray);
}

.react-select__placeholder_error {
  color: var(--danger);
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: var(--warning);
  color: var(--dark);
  font-size: 0.75rem;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2rem;
  left: 10px;
  white-space: normal !important;
  width: 150px;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

/* scroll bar width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* scroll bar Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--gray);
  border-radius: 20px;
}

/* scroll Handle */
::-webkit-scrollbar-thumb {
  background: var(--warning);
  border-radius: 20px;
}

/* scroll bar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--danger);
}
